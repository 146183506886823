.heading {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 0;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media all and (min-width: 1200px) {
  .Login {
    margin: 10% 33%;
    border-radius: 0.6rem;
    padding: 2rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6), 0 6px 20px 0 rgba(0, 0, 0, 0.6);
  }
  .Loginform {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .Loginform:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .showPasswordBtn {
    transform: translate(81%, -165%);
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .Login {
    margin: 20% 20%;
    border-radius: 0.6rem;
    padding: 2rem;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .Loginform {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .Loginform:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .showPasswordBtn {
    transform: translate(81%, -165%);
  }
}

@media (max-width: 700px) {
  .Login {
    margin: 40% 3%;
    border-radius: 0.6rem;
    padding: 5%;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .Loginform {
    margin: 3% auto;
    padding: 10px;
    width: 80%;
    font-size: 1rem;
    border-radius: 0.6rem;
    border: 2px solid #b4b4b4;
  }
  .Loginform:focus {
    outline: none;
    border: 2px solid #7c7c7c;
  }
  .showPasswordBtn {
    transform: translate(79%, -145%);
  }
}

.loginButton {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #fff;
  background-color: #012970;
  cursor: pointer;
}

.loginButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.registerButton {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #012970;
  color: #012970;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
}

.registerButton:hover {
  background-color: #012970;
  color: #fff;
}

.error {
  position: absolute;
  font-size: medium;
  color: orangered;
}

.buttons > hr {
  width: 80%;
  margin-top: 1.5rem;
  border-top: 0.5px solid#b4b4b4;
}

.buttons > p {
  color: #7c7c7c;
  width: 3rem;
  margin: -1.2rem auto 2rem;
  background: #fff;
}

.googleSignIn {
  margin: 1rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 100px;
  outline: none;
  padding: 0.5rem 1.5rem;
  border: 2px solid #4285f4;
  color: #4285f4;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
}

.googleButton {
  margin: 0 0.6rem 0 0;
  width: 1rem;
  height: 1rem;
}
