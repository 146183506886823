.teamContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.designation {
  font-size: 1.25rem;
  color: rgb(28, 26, 26);
  text-align: center;
  margin: 0.2rem 0;
}

.subDesignation {
  font-size: 1rem;
  color: rgb(24, 23, 23);
  margin: 0.2rem 0;
}

/* .profileImage {
  margin: 1rem 0;
  width: 200px;
  height: 200px;
  border-radius: 0.3rem;
} */

/* .cardContent {
  padding: 0.2rem;
  color: #012970;
  text-align: center;
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}

.department {
  text-align: center;
  font-size: 1rem;
  color: #012970;
  margin: 0.5rem 0 0.9rem 0;
  padding: 0 2rem;
} */

.cardFooter {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.7rem;
  margin: 0 auto 0;
  width: 15.5rem;
}

.email {
  font-size: 1rem;
  color: #383cc1;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
}

.email:hover {
  color: #012970;
}

.teamCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 16px;
  margin: auto;
  box-shadow: 0px 8px 16px 0px rgba(120, 120, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  height: 380px;
  width: 240px;
}

.teamCard:hover {
  transform: scale(1.05);
}

.imageContainer {
  position: relative;
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 16px;
}

.profileImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardContent {
  text-align: center;
}

.department {
  font-size: 1.2rem;
  color: #666;
  margin-top: 8px;
}
