.heading {
  font-size: 1.8rem;
  color: #fff;
  background-color: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.bTech,
.mTech,
.mSc {
  font-size: 1.8rem;
  color: #012970;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr {
  margin: 1rem auto;
  width: 20rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
