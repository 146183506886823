.reachUs {
  font-size: 30px;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.content {
  margin: 2rem 0rem;
  padding: 0.5rem 0rem;
}
