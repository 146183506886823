.contactCard {
  width: 290px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 1rem;
  margin: auto;
  background-color: rgb(251, 251, 251);
  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;
}

.contactCard:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.cardHeader {
  padding: 0.5rem;
  /* background-color: #012970; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
  font-style: italic;
}

.designation {
  font-size: 1.25rem;
  color: rgb(21, 20, 20);
  text-align: center;
  margin: 0.2rem 0;
}

.subDesignation {
  font-size: 0.9rem;
  color: rgb(19, 18, 18);
  margin: 0.2rem 0;
}

.profileImage {
  margin: 1rem 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.cardContent {
  padding: 0.2rem;
  color: #0e0e0f;
  text-align: center;
  border-bottom-left-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  font-style: italic;
}

.footer {
  padding: 0.7rem;
  margin: 0rem auto;
  width: 15rem;
}

.email {
  font-size: 0.9rem;
  color: #383cc1;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0.5rem;
}

.email:hover {
  color: #121414;
}

.phone {
  font-size: 0.9rem;
  color: #383cc1;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.phone:hover {
  color: #080909;
}

.social {
  height: 2rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0 0 0;
}

.github {
  color: black;
  margin: 0 0.5rem;
  width: 1.8rem;
  height: 1.8rem;
  text-decoration: none;
}

.linkedin {
  color: #0072b1;
  margin: 0 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  text-decoration: none;
}
