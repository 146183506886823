.heading {
  font-size: 1.8rem;
  color: white;
  background: #012970;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0rem 2rem 0rem;
  padding: 1rem;
}

.jaf {
  text-align: center;
  margin: 2rem 0rem;
  padding: 1rem;
}

.download {
  margin: 1rem auto;
  padding: 0 1rem;
}

.download > a {
  font-weight: 500;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
}

.iframe {
  margin: 2rem auto;
}

@media only screen and (max-width: 600px) {
  .download > a {
    font-size: 1rem;
  }
  .iframe {
    width: 95%;
    height: 60vh;
  }
}

@media only screen and (min-width: 600px) {
  .download > a {
    font-size: 1rem;
  }
  .iframe {
    width: 90%;
    height: 100vh;
  }
}

@media only screen and (min-width: 768px) {
  .download > a {
    font-size: 1rem;
  }
  .iframe {
    width: 80%;
    height: 100vh;
  }
}

@media only screen and (min-width: 992px) {
  .download > a {
    font-size: 1.2rem;
  }
  .iframe {
    width: 70%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1200px) {
  .download > a {
    font-size: 1.2rem;
  }
  .iframe {
    width: 60%;
    height: 100vh;
  }
}
