/* .root {
  max-width: 600px;
  height: auto;
} */

.slider {
  width: 90%;
  margin: 0 auto;
}
.gridContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gridItem {
  margin: 10px;
  width: calc(10% - 20px);
}

.PR_image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PR_image img {
  box-shadow: 0px 8px 16px 0px rgba(233, 238, 242, 0.2);
  border-radius: 5px;
  transition: 0.4s;
  height: 200px;
  width: 110px;
  text-align: center;
  object-fit: contain;
}

.PR_image:hover {
  cursor: pointer;
  /* transition: 0.4s;
  box-shadow: 0 4px 6px 0 rgb(1, 41, 112, 0.16),
    0 6px 15px 0 rgb(1, 41, 112, 0.16);
  transform: scale(1.08); */
}

/* .slick-prev:before {
  float: right;
}
.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  color: rgba(75, 74, 74, 0.856);
  opacity: 0.2;
} */

@media screen and (max-width: 1100px) {
  .gridItem {
    width: calc(10% - 20px);
  }
}

@media screen and (max-width: 1000px) {
  .gridItem {
    width: calc(20% - 20px);
  }
}

@media screen and (max-width: 600px) {
  .gridItem {
    width: calc(50% - 20px);
  }
}
