.list-type3 {
  margin: 50px;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.list-type3 div {
  float: left;
  height: 35px;
  line-height: 35px;
  position: relative;
  font-size: 15px;
  margin-bottom: 12px;
  font-family: 'Roboto', sans-serif;
  transition: background-color 1.5s ease;
}
.list-type3 div {
  padding: 0 60px 0 12px;
  margin: 8px;
  background: #012970;
  color: #fff;
  text-decoration: none;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}

.list-type3 div:before {
  content: '';
  float: left;
  position: absolute;
  top: 0;
  left: -12px;
  width: 0;
  height: 0;
  border-color: transparent #0089e0 transparent transparent;
  border-style: solid;
  border-width: 18px 12px 18px 0;
}
