.aboutus-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -25px;
  border-radius: 20px;
  padding: 30px 0;
}

.textContainer {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.5;
  max-width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0px 16px 32px 0px rgba(233, 238, 242, 0.4);
  transition: transform 0.2s ease-in-out;
}

.textContainer:hover {
  transform: scale(1.05);
}

.aboutUsTitle {
  font-size: 40px;
  margin-bottom: 1rem;
  text-align: center;
  color: rgb(29, 22, 66);
  /* font-family: 'Lato', sans-serif; */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 600px) {
  .textContainer {
    font-size: '1rem';
    width: 90%;
    padding: 20px;
  }
}
