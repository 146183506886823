.badge-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 4rem 0;
}

.ribbon {
  position: absolute;
  width: 100%;
  height: 100px;
  background-color: #9e6531;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}
