.program {
  width: 100%;
  text-align: center;
  font-weight: 550;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.program:hover {
  color: #012970;
}

.departments > div {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.department {
  width: 100%;
  margin: 0 0 0.5rem 0;
  color: black;
  text-decoration: underline 0.1rem white;
  transition: text-decoration-color 500ms, text-underline-offset 500ms;
}

.department:hover {
  color: #012970;
  text-decoration-color: #012970;
  text-underline-offset: 0.3rem;
}
