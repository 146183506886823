.carousel-wrapper {
  width: 700px;
  height: 400px;
  margin-inline: auto;
  border-color: rgb(0, 0, 0);
}

.slick-slide img {
  height: 400px;
  width: 700px;
  display: inline;
}

.slick-dots {
  bottom: 30px;
}

.slick-dots li {
  background: aliceblue;
  width: 8px;
  height: 8px;
  transition: transform 0.3s;
  border-radius: 20%;
}

.slick-dots .slick-active {
  background: rgb(24, 124, 211);
  transform: scale(1.8);
}

.slick-dots li button:before {
  display: none;
}

.slick-prev {
  left: 25px;
  z-index: 2;
}

.slick-next {
  right: 25px;
}

.carousel_container {
  justify-content: center;
  display: contents;
  max-width: 700px;
}

.carousel_container .carousel_grid {
  height: auto;
  max-width: 100%;
}

.MuiPaper-elevation1 {
  width: 700px;
}

@media screen and (max-width: 760px) {
  .MuiPaper-elevation1 {
    width: 100%;
    height: fit-content;
  }
  .slick-slide img {
    height: 40vh;
    width: 100%;
  }
  .carousel-wrapper {
    width: 100%;
    height: 40vh;
  }
  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li {
    width: 5px;
    height: 5px;
  }

  .slick-dots .slick-active {
    transform: scale(1.6);
  }
}
