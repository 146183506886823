.department {
  width: 100%;
  text-align: center;
  font-weight: 550;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.department:hover {
  color: #012970;
}

.description {
  font-size: '1rem';
  color: 'black';
}

.description > p {
  margin-top: 0;
}

.brochure {
  width: 100%;
  text-align: center;
  margin: 0 auto 0.5rem auto;
  color: black;
  text-decoration: underline 0.1rem white;
  transition: text-decoration-color 500ms, text-underline-offset 500ms;
}

.brochure > i {
  margin-left: 0.2rem;
}

.brochure:hover {
  color: #012970;
  text-decoration-color: #012970;
  text-underline-offset: 0.3rem;
}
